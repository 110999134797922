import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <a id="equipment"></a><h2>Equipment</h2>
    <a id="weapons"></a><h3>WEAPONS</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td className="line"> </td>
        </tr>
      </tbody>
    </table>
    <a id="weapon-categories"></a><h5>WEAPON CATEGORIES</h5>
    <p className="initial">Weapons are grouped into several interlocking
sets of categories. These categories pertain to what training is needed
to become proficient in a weapon&#8217;s use (simple, martial, or exotic),
the weapon&#8217;s usefulness either in close combat (melee) or at a distance
(ranged, which includes both thrown and projectile weapons), its
relative encumbrance (light, one-handed, or two-handed), and its size
(Small, Medium, or Large).</p>
    <a id="simple-martial-and-exotic-weapons"></a><p><b>Simple, Martial, and Exotic Weapons</b>: Anybody but a
druid, monk, rogue, or wizard is proficient with all simple weapons.
Barbarians, fighters, paladins, and rangers are proficient with all
simple and all martial weapons. Characters of other classes are
proficient with an assortment of mainly simple weapons and possibly
also some martial or even exotic weapons. A character who uses a weapon
with which he or she is not proficient takes a &#8211;4 penalty on attack
rolls.</p>
    <a id="melee-and-ranged-weapons"></a><p><b>Melee and Ranged Weapons</b>: Melee weapons are used for
making melee attacks, though some of them can be thrown as well. Ranged
weapons are thrown weapons or projectile weapons that are not effective
in melee.</p>
    <a id="reach-weapons"></a><p><i>Reach Weapons</i>: Glaives, guisarmes, lances, longspears,
ranseurs, spiked chains, and whips are reach weapons. A reach weapon is
a melee weapon that allows its wielder to strike at targets that aren&#8217;t
adjacent to him or her. Most reach double the wielder&#8217;s natural reach,
meaning that a typical Small or Medium wielder of such a weapon can
attack a creature 10 feet away, but not a creature in an adjacent
square. A typical Large character wielding a reach weapon of the
appropriate size can attack a creature 15 or 20 feet away, but not
adjacent creatures or creatures up to 10 feet away.</p>
    <a id="double-weapons"></a><p><i>Double Weapons</i>: Dire flails, dwarven urgroshes, gnome
hooked hammers, orc double axes, quarterstaffs, and two-bladed swords
are double weapons. A character can fight with both ends of a double
weapon as if fighting with two weapons, but he or she incurs all the
normal attack penalties associated with two-weapon combat, just as
though the character were wielding a one-handed weapon and a light
weapon. The character can also choose to use a double weapon two
handed, attacking with only one end of it. A creature wielding a double
weapon in one hand can&#8217;t use it as a double weapon&#8212;only one end of the
weapon can be used in any given round.</p>
    <a id="thrown-weapons"></a><p><i>Thrown Weapons</i>: Daggers, clubs, shortspears, spears,
darts, javelins, throwing axes, light hammers, tridents, shuriken, and
nets are thrown weapons. The wielder applies his or her Strength
modifier to damage dealt by thrown weapons (except for splash weapons).
It is possible to throw a weapon that isn&#8217;t designed to be thrown (that
is, a melee weapon that doesn&#8217;t have a numeric entry in the Range
Increment column on Table: Weapons), but a character who does so takes
a &#8211;4 penalty on the attack roll. Throwing a light or one-handed weapon
is a standard action, while throwing a two-handed weapon is a
full-round action. Regardless of the type of weapon, such an attack
scores a threat only on a natural roll of 20 and deals double damage on
a critical hit. Such a weapon has a range increment of 10 feet.</p>
    <a id="projectile-weapons"></a><p><i>Projectile Weapons</i>: Light crossbows, slings, heavy
crossbows, shortbows, composite shortbows, longbows, composite
longbows, hand crossbows, and repeating crossbows are projectile
weapons. Most projectile weapons require two hands to use (see specific
weapon descriptions). A character gets no Strength bonus on damage
rolls with a projectile weapon unless it&#8217;s a specially built composite
shortbow, specially built composite longbow, or sling. If the character
has a penalty for low Strength, apply it to damage rolls when he or she
uses a bow or a sling.</p>
    <a id="ammunition"></a><p><i>Ammunition</i>: Projectile weapons use ammunition: arrows
(for bows), bolts (for crossbows), or sling bullets (for slings). When
using a bow, a character can draw ammunition as a free action;
crossbows and slings require an action for reloading. Generally
speaking, ammunition that hits its target is destroyed or rendered
useless, while normal ammunition that misses has a 50% chance of being
destroyed or lost.</p>
    <p>Although they are thrown weapons, shuriken are treated as
ammunition for the purposes of drawing them, crafting masterwork or
otherwise special versions of them (see Masterwork Weapons), and what
happens to them after they are thrown.</p>
    <a id="light-one-handed-and-two-handed-melee-weapons"></a><p><b>Light, One-Handed, and Two-Handed Melee Weapons</b>: This
designation is a measure of how much effort it takes to wield a weapon
in combat. It indicates whether a melee weapon, when wielded by a
character of the weapon&#8217;s size category, is considered a light weapon,
a one-handed weapon, or a two-handed weapon.</p>
    <a id="light-weapon"></a><p><i>Light</i>: A light weapon is easier to use in one&#8217;s off
hand than a one-handed weapon is, and it can be used while grappling. A
light weapon is used in one hand. Add the wielder&#8217;s Strength bonus (if
any) to damage rolls for melee attacks with a light weapon if it&#8217;s used
in the primary hand, or one-half the wielder&#8217;s Strength bonus if it&#8217;s
used in the off hand. Using two hands to wield a light weapon gives no
advantage on damage; the Strength bonus applies as though the weapon
were held in the wielder&#8217;s primary hand only.</p>
    <p>An unarmed strike is always considered a light weapon.</p>
    <a id="one-handed-weapon"></a><p><i>One-Handed</i>: A one-handed weapon can be used in either
the primary hand or the off hand. Add the wielder&#8217;s Strength bonus to
damage rolls for melee attacks with a one-handed weapon if it&#8217;s used in
the primary hand, or 1/2 his or her Strength bonus if it&#8217;s used in the
off hand. If a one-handed weapon is wielded with two hands during melee
combat, add 1-1/2 times the character&#8217;s Strength bonus to damage rolls.</p>
    <a id="two-handed-weapon"></a><p><i>Two-Handed</i>: Two hands are required to use a two-handed
melee weapon effectively. Apply 1-1/2 times the character&#8217;s Strength
bonus to damage rolls for melee attacks with such a weapon. </p>
    <a id="weapon-size"></a><p><b>Weapon Size</b>: Every weapon has a size category. This
designation indicates the size of the creature for which the weapon was
designed.</p>
    <p>A weapon&#8217;s size category isn&#8217;t the same as its size as an
object. Instead, a weapon&#8217;s size category is keyed to the size of the
intended wielder. In general, a light weapon is an object two size
categories smaller than the wielder, a one-handed weapon is an object
one size category smaller than the wielder, and a two-handed weapon is
an object of the same size category as the wielder.</p>
    <a id="inappropriately-sized-weapons"></a><p><i>Inappropriately Sized Weapons</i>: A creature can&#8217;t make
optimum use of a weapon that isn&#8217;t properly sized for it. A cumulative
&#8211;2 penalty applies on attack rolls for each size category of difference
between the size of its intended wielder and the size of its actual
wielder. If the creature isn&#8217;t proficient with the weapon a &#8211;4
nonproficiency penalty also applies.</p>
    <p>The measure of how much effort it takes to use a weapon
(whether the weapon is designated as a light, one-handed, or two-handed
weapon for a particular wielder) is altered by one step for each size
category of difference between the wielder&#8217;s size and the size of the
creature for which the weapon was designed. If a weapon&#8217;s designation
would be changed to something other than light, one-handed, or
two-handed by this alteration, the creature can&#8217;t wield the weapon at
all.</p>
    <a id="improvised-weapons"></a><p><b>Improvised Weapons</b>: Sometimes objects not crafted to be
weapons nonetheless see use in combat. Because such objects are not
designed for this use, any creature that uses one in combat is
considered to be nonproficient with it and takes a &#8211;4 penalty on attack
rolls made with that object. To determine the size category and
appropriate damage for an improvised weapon, compare its relative size
and damage potential to the weapon list to find a reasonable match. An
improvised weapon scores a threat on a natural roll of 20 and deals
double damage on a critical hit. An improvised thrown weapon has a
range increment of 10 feet.</p>
    <a id="weapon-qualities"></a><h5>WEAPON QUALITIES</h5>
    <p className="initial">Here is the format for weapon entries (given
as column headings on Table: Weapons, below).</p>
    <a id="weapon-cost"></a><p><b>Cost</b>: This value is the weapon&#8217;s cost in gold pieces
(gp) or silver pieces (sp). The cost includes miscellaneous gear that
goes with the weapon. This cost is the same for a Small or Medium
version of the weapon. A Large version costs twice the listed price.</p>
    <a id="weapon-damage"></a><p><b>Damage</b>: The Damage columns give the damage dealt by the
weapon on a successful hit. The column labeled &#8220;Dmg (S)&#8221; is for Small
weapons. The column labeled &#8220;Dmg (M)&#8221; is for Medium weapons. If two
damage ranges are given then the weapon is a double weapon. Use the
second damage figure given for the double weapon&#8217;s extra attack. Table:
Tiny and Large Weapon Damage gives weapon damage values for weapons of
those sizes.</p>
    <a id="table-tiny-and-large-weapon-damage"></a><p><b>Table: Tiny and Large Weapon Damage</b></p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "125px"
          }}>Medium
Weapon Damage</th>
          <th style={{
            "width": "125px"
          }}>Tiny
Weapon Damage</th>
          <th style={{
            "width": "125px"
          }}>Large
Weapon Damage</th>
        </tr>
        <tr className="odd-row">
          <td>1d2</td>
          <td>&#8212;</td>
          <td>1d3</td>
        </tr>
        <tr>
          <td>1d3</td>
          <td>1</td>
          <td>1d4</td>
        </tr>
        <tr className="odd-row">
          <td>1d4</td>
          <td>1d2</td>
          <td>1d6</td>
        </tr>
        <tr>
          <td>1d6</td>
          <td>1d3</td>
          <td>1d8</td>
        </tr>
        <tr className="odd-row">
          <td>1d8</td>
          <td>1d4</td>
          <td>2d6</td>
        </tr>
        <tr>
          <td>1d10</td>
          <td>1d6</td>
          <td>2d8</td>
        </tr>
        <tr className="odd-row">
          <td>1d12</td>
          <td>1d8</td>
          <td>3d6</td>
        </tr>
        <tr>
          <td>2d4</td>
          <td>1d4</td>
          <td>2d6</td>
        </tr>
        <tr className="odd-row">
          <td>2d6</td>
          <td>1d8</td>
          <td>3d6</td>
        </tr>
        <tr>
          <td>2d8</td>
          <td>1d10</td>
          <td>3d8</td>
        </tr>
        <tr className="odd-row">
          <td>2d10</td>
          <td>2d6</td>
          <td>4d8</td>
        </tr>
      </tbody>
    </table>
    <a id="weapon-critical"></a><p><b>Critical</b>: The entry in this column notes how the weapon
is used with the rules for critical hits. When your character scores a
critical hit, roll the damage two, three, or four times, as indicated
by its critical multiplier (using all applicable modifiers on each
roll), and add all the results together.</p>
    <p><i>Exception</i>: Extra damage over and above a weapon&#8217;s
normal damage is not multiplied when you score a critical hit.</p>
    <p><i>x2</i>: The weapon deals double damage on a critical hit.</p>
    <p><i>x3</i>: The weapon deals triple damage on a critical hit.</p>
    <p><i>x3/x4</i>: One head of this double weapon deals triple
damage on a critical hit. The other head deals quadruple damage on a
critical hit.</p>
    <p><i>x4</i>: The weapon deals quadruple damage on a critical hit.</p>
    <p><i>19&#8211;20/x2</i>: The weapon scores a threat on a natural roll
of 19 or 20 (instead of just 20) and deals double damage on a critical
hit. (The weapon has a threat range of 19&#8211;20.)</p>
    <p><i>18&#8211;20/x2</i>: The weapon scores a threat on a natural roll
of 18, 19, or 20 (instead of just 20) and deals double damage on a
critical hit. (The weapon has a threat range of 18&#8211;20.)</p>
    <a id="weapon-range-increment"></a><p><b>Range Increment</b>: Any attack at less than this distance
is not penalized for range. However, each full range increment imposes
a cumulative &#8211;2 penalty on the attack roll. A thrown weapon has a
maximum range of five range increments. A projectile weapon can shoot
out to ten range increments.</p>
    <a id="weapon-weight"></a><p><b>Weight</b>: This column gives the weight of a Medium
version of the weapon. Halve this number for Small weapons and double
it for Large weapons.</p>
    <a id="weapon-type"></a><p><b>Type</b>: Weapons are classified according to the type of
damage they deal: bludgeoning, piercing, or slashing. Some monsters may
be resistant or immune to attacks from certain types of weapons.</p>
    <p>Some weapons deal damage of multiple types. If a weapon is of
two types, the damage it deals is not half one type and half another;
all of it is both types. Therefore, a creature would have to be immune
to both types of damage to ignore any of the damage from such a weapon.</p>
    <p>In other cases, a weapon can deal either of two types of
damage. In a situation when the damage type is significant, the wielder
can choose which type of damage to deal with such a weapon.</p>
    <p><b>Special</b>: Some weapons have special features. See the
weapon descriptions for details.</p>
    <a id="weapon-descriptions"></a><h5>WEAPON DESCRIPTIONS</h5>
    <a id="table-weapons"></a><p><b>Table: Weapons</b></p>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "25%"
          }}>Simple Weapons</th>
          <th style={{
            "width": "7%"
          }}>Cost</th>
          <th style={{
            "width": "7%"
          }}>Dmg (S)</th>
          <th style={{
            "width": "7%"
          }}>Dmg (M)</th>
          <th style={{
            "width": "7%"
          }}>Critical</th>
          <th style={{
            "width": "12%"
          }}>Range Increment</th>
          <th style={{
            "width": "7%"
          }}>Weight<sup>1</sup></th>
          <th>Type<sup>2</sup></th>
        </tr>
        <tr className="odd-row">
          <td><i>Unarmed Attacks</i></td>
          <td>
          </td>
          <td>
          </td>
          <td>
          </td>
          <td>
          </td>
          <td>
          </td>
          <td>
          </td>
          <td>
          </td>
        </tr>
        <tr>
          <td className="indent">Gauntlet</td>
          <td>2 gp</td>
          <td>1d2</td>
          <td>1d3</td>
          <td>x2</td>
          <td>&#8212;</td>
          <td>1 lb.</td>
          <td>Bludgeoning</td>
        </tr>
        <tr className="odd-row">
          <td className="indent">Unarmed strike</td>
          <td>&#8212;</td>
          <td>1d2<sup>3</sup></td>
          <td>1d3<sup>3</sup></td>
          <td>x2</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>Bludgeoning</td>
        </tr>
        <tr>
          <td><i>Light Melee Weapons</i></td>
          <td>
          </td>
          <td>
          </td>
          <td>
          </td>
          <td>
          </td>
          <td>
          </td>
          <td>
          </td>
          <td>
          </td>
        </tr>
        <tr className="odd-row">
          <td className="indent">Dagger</td>
          <td>2 gp</td>
          <td>1d3</td>
          <td>1d4</td>
          <td>19&#8211;20/x2</td>
          <td>10 ft.</td>
          <td>1 lb.</td>
          <td>Piercing or slashing</td>
        </tr>
        <tr>
          <td className="indent">Dagger, punching</td>
          <td>2 gp</td>
          <td>1d3</td>
          <td>1d4</td>
          <td>x3</td>
          <td>&#8212;</td>
          <td>1 lb.</td>
          <td>Piercing</td>
        </tr>
        <tr className="odd-row">
          <td className="indent">Gauntlet, spiked</td>
          <td>5 gp</td>
          <td>1d3</td>
          <td>1d4</td>
          <td>x2</td>
          <td>&#8212;</td>
          <td>1 lb.</td>
          <td>Piercing</td>
        </tr>
        <tr>
          <td className="indent">Mace, light</td>
          <td>5 gp</td>
          <td>1d4</td>
          <td>1d6</td>
          <td>x2</td>
          <td>&#8212;</td>
          <td>4 lb.</td>
          <td>Bludgeoning</td>
        </tr>
        <tr className="odd-row">
          <td className="indent">Sickle</td>
          <td>6 gp</td>
          <td>1d4</td>
          <td>1d6</td>
          <td>x2</td>
          <td>&#8212;</td>
          <td>2 lb.</td>
          <td>Slashing</td>
        </tr>
        <tr>
          <td><i>One-Handed Melee Weapons</i></td>
          <td>
          </td>
          <td>
          </td>
          <td>
          </td>
          <td>
          </td>
          <td>
          </td>
          <td>
          </td>
          <td>
          </td>
        </tr>
        <tr className="odd-row">
          <td className="indent">Club</td>
          <td>&#8212;</td>
          <td>1d4</td>
          <td>1d6</td>
          <td>x2</td>
          <td>10 ft.</td>
          <td>3 lb.</td>
          <td>Bludgeoning</td>
        </tr>
        <tr>
          <td className="indent">Mace, heavy</td>
          <td>12 gp</td>
          <td>1d6</td>
          <td>1d8</td>
          <td>x2</td>
          <td>&#8212;</td>
          <td>8 lb.</td>
          <td>Bludgeoning</td>
        </tr>
        <tr className="odd-row">
          <td className="indent">Morningstar</td>
          <td>8 gp</td>
          <td>1d6</td>
          <td>1d8</td>
          <td>x2</td>
          <td>&#8212;</td>
          <td>6 lb.</td>
          <td>Bludgeoning and piercing</td>
        </tr>
        <tr>
          <td className="indent">Shortspear</td>
          <td>1 gp</td>
          <td>1d4</td>
          <td>1d6</td>
          <td>x2</td>
          <td>20 ft.</td>
          <td>3 lb.</td>
          <td>Piercing</td>
        </tr>
        <tr className="odd-row">
          <td><i>Two-Handed Melee Weapons</i></td>
          <td>
          </td>
          <td>
          </td>
          <td>
          </td>
          <td>
          </td>
          <td>
          </td>
          <td>
          </td>
          <td>
          </td>
        </tr>
        <tr>
          <td className="indent">Longspear<sup>4</sup></td>
          <td>5 gp</td>
          <td>1d6</td>
          <td>1d8</td>
          <td>x3</td>
          <td>&#8212;</td>
          <td>9 lb.</td>
          <td>Piercing</td>
        </tr>
        <tr className="odd-row">
          <td className="indent">Quarterstaff<sup>5</sup></td>
          <td>&#8212;</td>
          <td>1d4/1d4</td>
          <td>1d6/1d6</td>
          <td>x2</td>
          <td>&#8212;</td>
          <td>4 lb.</td>
          <td>Bludgeoning</td>
        </tr>
        <tr>
          <td className="indent">Spear</td>
          <td>2 gp</td>
          <td>1d6</td>
          <td>1d8</td>
          <td>x3</td>
          <td>20 ft.</td>
          <td>6 lb.</td>
          <td>Piercing</td>
        </tr>
        <tr className="odd-row">
          <td><i>Ranged Weapons</i></td>
          <td>
          </td>
          <td>
          </td>
          <td>
          </td>
          <td>
          </td>
          <td>
          </td>
          <td>
          </td>
          <td>
          </td>
        </tr>
        <tr>
          <td className="indent">Crossbow, heavy</td>
          <td>50 gp</td>
          <td>1d8</td>
          <td>1d10</td>
          <td>19&#8211;20/x2</td>
          <td>120 ft.</td>
          <td>8 lb.</td>
          <td>Piercing</td>
        </tr>
        <tr className="odd-row">
          <td className="indent2">Bolts, crossbow (10)</td>
          <td>1 gp</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>1 lb.</td>
          <td>&#8212;</td>
        </tr>
        <tr>
          <td className="indent">Crossbow, light</td>
          <td>35 gp</td>
          <td>1d6</td>
          <td>1d8</td>
          <td>19&#8211;20/x2</td>
          <td>80 ft.</td>
          <td>4 lb.</td>
          <td>Piercing</td>
        </tr>
        <tr className="odd-row">
          <td className="indent2">Bolts, crossbow (10)</td>
          <td>1 gp</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>1 lb.</td>
          <td>&#8212;</td>
        </tr>
        <tr>
          <td className="indent">Dart</td>
          <td>5 sp</td>
          <td>1d3</td>
          <td>1d4</td>
          <td>x2</td>
          <td>20 ft.</td>
          <td>1/2 lb.</td>
          <td>Piercing</td>
        </tr>
        <tr className="odd-row">
          <td className="indent">Javelin</td>
          <td>1 gp</td>
          <td>1d4</td>
          <td>1d6</td>
          <td>x2</td>
          <td>30 ft.</td>
          <td>2 lb.</td>
          <td>Piercing</td>
        </tr>
        <tr>
          <td className="indent">Sling</td>
          <td>&#8212;</td>
          <td>1d3</td>
          <td>1d4</td>
          <td>x2</td>
          <td>50 ft.</td>
          <td>0 lb.</td>
          <td>Bludgeoning</td>
        </tr>
        <tr className="odd-row">
          <td className="indent2">Bullets, sling (10)</td>
          <td>1 sp</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>5 lb.</td>
          <td>&#8212;</td>
        </tr>
      </tbody>
    </table>
    <table style={{
      "marginTop": "0.5em",
      "width": "100%"
    }} border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "25%"
          }}>Martial Weapons</th>
          <th style={{
            "width": "7%"
          }}>Cost</th>
          <th style={{
            "width": "7%"
          }}>Dmg (S)</th>
          <th style={{
            "width": "7%"
          }}>Dmg (M)</th>
          <th style={{
            "width": "7%"
          }}>Critical</th>
          <th style={{
            "width": "12%"
          }}>Range Increment</th>
          <th style={{
            "width": "7%"
          }}>Weight<sup>1</sup></th>
          <th>Type<sup>2</sup></th>
        </tr>
        <tr className="odd-row">
          <td><i>Light Melee Weapons</i></td>
          <td>
          </td>
          <td>
          </td>
          <td>
          </td>
          <td>
          </td>
          <td>
          </td>
          <td>
          </td>
          <td>
          </td>
        </tr>
        <tr>
          <td className="indent">Axe, throwing</td>
          <td>8 gp</td>
          <td>1d4</td>
          <td>1d6</td>
          <td>x2</td>
          <td>10 ft.</td>
          <td>2 lb.</td>
          <td>Slashing</td>
        </tr>
        <tr className="odd-row">
          <td className="indent">Hammer, light</td>
          <td>1 gp</td>
          <td>1d3</td>
          <td>1d4</td>
          <td>x2</td>
          <td>20 ft.</td>
          <td>2 lb.</td>
          <td>Bludgeoning</td>
        </tr>
        <tr>
          <td className="indent">Handaxe</td>
          <td>6 gp</td>
          <td>1d4</td>
          <td>1d6</td>
          <td>x3</td>
          <td>&#8212;</td>
          <td>3 lb.</td>
          <td>Slashing</td>
        </tr>
        <tr className="odd-row">
          <td className="indent">Kukri</td>
          <td>8 gp</td>
          <td>1d3</td>
          <td>1d4</td>
          <td>18&#8211;20/x2</td>
          <td>&#8212;</td>
          <td>2 lb.</td>
          <td>Slashing</td>
        </tr>
        <tr>
          <td className="indent">Pick, light</td>
          <td>4 gp</td>
          <td>1d3</td>
          <td>1d4</td>
          <td>x4</td>
          <td>&#8212;</td>
          <td>3 lb.</td>
          <td>Piercing</td>
        </tr>
        <tr className="odd-row">
          <td className="indent">Sap</td>
          <td>1 gp</td>
          <td>1d4<sup>3</sup></td>
          <td>1d6<sup>3</sup></td>
          <td>x2</td>
          <td>&#8212;</td>
          <td>2 lb.</td>
          <td>Bludgeoning</td>
        </tr>
        <tr>
          <td className="indent">Shield, light</td>
          <td>special</td>
          <td>1d2</td>
          <td>1d3</td>
          <td>x2</td>
          <td>&#8212;</td>
          <td>special</td>
          <td>Bludgeoning</td>
        </tr>
        <tr className="odd-row">
          <td className="indent">Spiked armor</td>
          <td>special</td>
          <td>1d4</td>
          <td>1d6</td>
          <td>x2</td>
          <td>&#8212;</td>
          <td>special</td>
          <td>Piercing</td>
        </tr>
        <tr>
          <td className="indent">Spiked, shield light</td>
          <td>special</td>
          <td>1d3</td>
          <td>1d4</td>
          <td>x2</td>
          <td>&#8212;</td>
          <td>special</td>
          <td>Piercing</td>
        </tr>
        <tr className="odd-row">
          <td className="indent">Sword short</td>
          <td>10 gp</td>
          <td>1d4</td>
          <td>1d6</td>
          <td>19&#8211;20/x2</td>
          <td>&#8212;</td>
          <td>2 lb.</td>
          <td>Piercing</td>
        </tr>
        <tr>
          <td><i>One-Handed Melee Weapons</i></td>
          <td>
          </td>
          <td>
          </td>
          <td>
          </td>
          <td>
          </td>
          <td>
          </td>
          <td>
          </td>
          <td>
          </td>
        </tr>
        <tr className="odd-row">
          <td className="indent">Battleaxe</td>
          <td>10 gp</td>
          <td>1d6</td>
          <td>1d8</td>
          <td>x3</td>
          <td>&#8212;</td>
          <td>6 lb.</td>
          <td>Slashing</td>
        </tr>
        <tr>
          <td className="indent">Flail</td>
          <td>8 gp</td>
          <td>1d6</td>
          <td>1d8</td>
          <td>x2</td>
          <td>&#8212;</td>
          <td>5 lb.</td>
          <td>Bludgeoning</td>
        </tr>
        <tr className="odd-row">
          <td className="indent">Longsword</td>
          <td>15 gp</td>
          <td>1d6</td>
          <td>1d8</td>
          <td>19&#8211;20/x2</td>
          <td>&#8212;</td>
          <td>4 lb.</td>
          <td>Slashing</td>
        </tr>
        <tr>
          <td className="indent">Pick, heavy</td>
          <td>8 gp</td>
          <td>1d4</td>
          <td>1d6</td>
          <td>x4</td>
          <td>&#8212;</td>
          <td>6 lb.</td>
          <td>Piercing</td>
        </tr>
        <tr className="odd-row">
          <td className="indent">Rapier</td>
          <td>20 gp</td>
          <td>1d4</td>
          <td>1d6</td>
          <td>18&#8211;20/x2</td>
          <td>&#8212;</td>
          <td>2 lb.</td>
          <td>Piercing</td>
        </tr>
        <tr>
          <td className="indent">Scimitar</td>
          <td>15 gp</td>
          <td>1d4</td>
          <td>1d6</td>
          <td>18&#8211;20/x2</td>
          <td>&#8212;</td>
          <td>4 lb.</td>
          <td>Slashing</td>
        </tr>
        <tr className="odd-row">
          <td className="indent">Shield, heavy</td>
          <td>special</td>
          <td>1d3</td>
          <td>1d4</td>
          <td>x2</td>
          <td>&#8212;</td>
          <td>special</td>
          <td>Bludgeoning</td>
        </tr>
        <tr>
          <td className="indent">Spiked shield, heavy</td>
          <td>special</td>
          <td>1d4</td>
          <td>1d6</td>
          <td>x2</td>
          <td>&#8212;</td>
          <td>special</td>
          <td>Piercing</td>
        </tr>
        <tr className="odd-row">
          <td className="indent">Trident</td>
          <td>15 gp</td>
          <td>1d6</td>
          <td>1d8</td>
          <td>x2</td>
          <td>10 ft.</td>
          <td>4 lb.</td>
          <td>Piercing</td>
        </tr>
        <tr>
          <td className="indent">Warhammer</td>
          <td>12 gp</td>
          <td>1d6</td>
          <td>1d8</td>
          <td>x3</td>
          <td>&#8212;</td>
          <td>5 lb.</td>
          <td>Bludgeoning</td>
        </tr>
        <tr className="odd-row">
          <td><i>Two-Handed Melee Weapons</i></td>
          <td>
          </td>
          <td>
          </td>
          <td>
          </td>
          <td>
          </td>
          <td>
          </td>
          <td>
          </td>
          <td>
          </td>
        </tr>
        <tr>
          <td className="indent">Falchion</td>
          <td>75 gp</td>
          <td>1d6</td>
          <td>2d4</td>
          <td>18&#8211;20/x2</td>
          <td>&#8212;</td>
          <td>8 lb.</td>
          <td>Slashing</td>
        </tr>
        <tr className="odd-row">
          <td className="indent">Glaive<sup>4</sup></td>
          <td>8 gp</td>
          <td>1d8</td>
          <td>1d10</td>
          <td>x3</td>
          <td>&#8212;</td>
          <td>10 lb.</td>
          <td>Slashing</td>
        </tr>
        <tr>
          <td className="indent">Greataxe</td>
          <td>20 gp</td>
          <td>1d10</td>
          <td>1d12</td>
          <td>x3</td>
          <td>&#8212;</td>
          <td>12 lb.</td>
          <td>Slashing</td>
        </tr>
        <tr className="odd-row">
          <td className="indent">Greatclub</td>
          <td>5 gp</td>
          <td>1d8</td>
          <td>1d10</td>
          <td>x2</td>
          <td>&#8212;</td>
          <td>8 lb.</td>
          <td>Bludgeoning</td>
        </tr>
        <tr>
          <td className="indent">Flail, heavy</td>
          <td>15 gp</td>
          <td>1d8</td>
          <td>1d10</td>
          <td>19&#8211;20/x2</td>
          <td>&#8212;</td>
          <td>10 lb.</td>
          <td>Bludgeoning</td>
        </tr>
        <tr className="odd-row">
          <td className="indent">Greatsword</td>
          <td>50 gp</td>
          <td>1d10</td>
          <td>2d6</td>
          <td>19&#8211;20/x2</td>
          <td>&#8212;</td>
          <td>8 lb.</td>
          <td>Slashing</td>
        </tr>
        <tr>
          <td className="indent">Guisarme<sup>4</sup></td>
          <td>9 gp</td>
          <td>1d6</td>
          <td>2d4</td>
          <td>x3</td>
          <td>&#8212;</td>
          <td>12 lb.</td>
          <td>Slashing</td>
        </tr>
        <tr className="odd-row">
          <td className="indent">Halberd</td>
          <td>10 gp</td>
          <td>1d8</td>
          <td>1d10</td>
          <td>x3</td>
          <td>&#8212;</td>
          <td>12 lb.</td>
          <td>Piercing or slashing</td>
        </tr>
        <tr>
          <td className="indent">Lance<sup>4</sup></td>
          <td>10 gp</td>
          <td>1d6</td>
          <td>1d8</td>
          <td>x3</td>
          <td>&#8212;</td>
          <td>10 lb.</td>
          <td>Piercing</td>
        </tr>
        <tr className="odd-row">
          <td className="indent">Ranseur<sup>4</sup></td>
          <td>10 gp</td>
          <td>1d6</td>
          <td>2d4</td>
          <td>x3</td>
          <td>&#8212;</td>
          <td>12 lb.</td>
          <td>Piercing</td>
        </tr>
        <tr>
          <td className="indent">Scythe</td>
          <td>18 gp</td>
          <td>1d6</td>
          <td>2d4</td>
          <td>x4</td>
          <td>&#8212;</td>
          <td>10 lb.</td>
          <td>Piercing or slashing</td>
        </tr>
        <tr className="odd-row">
          <td><i>Ranged Weapons</i></td>
          <td>
          </td>
          <td>
          </td>
          <td>
          </td>
          <td>
          </td>
          <td>
          </td>
          <td>
          </td>
          <td>
          </td>
        </tr>
        <tr>
          <td className="indent">Longbow</td>
          <td>75 gp</td>
          <td>1d6</td>
          <td>1d8</td>
          <td>x3</td>
          <td>100 ft.</td>
          <td>3 lb.</td>
          <td>Piercing</td>
        </tr>
        <tr className="odd-row">
          <td className="indent2">Arrows (20)</td>
          <td>1 gp</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>3 lb.</td>
          <td>&#8212;</td>
        </tr>
        <tr>
          <td className="indent">Longbow, composite</td>
          <td>100 gp</td>
          <td>1d6</td>
          <td>1d8</td>
          <td>x3</td>
          <td>110 ft.</td>
          <td>3 lb.</td>
          <td>Piercing</td>
        </tr>
        <tr className="odd-row">
          <td className="indent2">Arrows (20)</td>
          <td>1 gp</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>3 lb.</td>
          <td>&#8212;</td>
        </tr>
        <tr>
          <td className="indent">Shortbow</td>
          <td>30 gp</td>
          <td>1d4</td>
          <td>1d6</td>
          <td>x3</td>
          <td>60 ft.</td>
          <td>2 lb.</td>
          <td>Piercing</td>
        </tr>
        <tr className="odd-row">
          <td className="indent2">Arrows (20)</td>
          <td>1 gp</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>3 lb.</td>
          <td>&#8212;</td>
        </tr>
        <tr>
          <td className="indent">Shortbow, composite</td>
          <td>75 gp</td>
          <td>1d4</td>
          <td>1d6</td>
          <td>x3</td>
          <td>70 ft.</td>
          <td>2 lb.</td>
          <td>Piercing</td>
        </tr>
        <tr className="odd-row">
          <td className="indent2">Arrows (20)</td>
          <td>1 gp</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>3 lb.</td>
          <td>&#8212;</td>
        </tr>
      </tbody>
    </table>
    <table style={{
      "marginTop": "0.5em",
      "width": "100%"
    }} border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "25%"
          }}>Exotic Weapons</th>
          <th style={{
            "width": "7%"
          }}>Cost</th>
          <th style={{
            "width": "7%"
          }}>Dmg (S)</th>
          <th style={{
            "width": "7%"
          }}>Dmg (M)</th>
          <th style={{
            "width": "7%"
          }}>Critical</th>
          <th style={{
            "width": "12%"
          }}>Range Increment</th>
          <th style={{
            "width": "7%"
          }}>Weight<sup>1</sup></th>
          <th>Type<sup>2</sup></th>
        </tr>
        <tr className="odd-row">
          <td><i>Light Melee Weapons</i></td>
          <td>
          </td>
          <td>
          </td>
          <td>
          </td>
          <td>
          </td>
          <td>
          </td>
          <td>
          </td>
          <td>
          </td>
        </tr>
        <tr>
          <td className="indent">Kama</td>
          <td>2 gp</td>
          <td>1d4</td>
          <td>1d6</td>
          <td>x2</td>
          <td>&#8212;</td>
          <td>2 lb.</td>
          <td>Slashing</td>
        </tr>
        <tr className="odd-row">
          <td className="indent">Nunchaku</td>
          <td>2 gp</td>
          <td>1d4</td>
          <td>1d6</td>
          <td>x2</td>
          <td>&#8212;</td>
          <td>2 lb.</td>
          <td>Bludgeoning</td>
        </tr>
        <tr>
          <td className="indent">Sai</td>
          <td>1 gp</td>
          <td>1d3</td>
          <td>1d4</td>
          <td>x2</td>
          <td>10 ft.</td>
          <td>1 lb.</td>
          <td>Bludgeoning</td>
        </tr>
        <tr className="odd-row">
          <td className="indent">Siangham</td>
          <td>3 gp</td>
          <td>1d4</td>
          <td>1d6</td>
          <td>x2</td>
          <td>&#8212;</td>
          <td>1 lb.</td>
          <td>Piercing</td>
        </tr>
        <tr>
          <td><i>One-Handed Melee Weapons</i></td>
          <td>
          </td>
          <td>
          </td>
          <td>
          </td>
          <td>
          </td>
          <td>
          </td>
          <td>
          </td>
          <td>
          </td>
        </tr>
        <tr className="odd-row">
          <td className="indent">Sword, bastard</td>
          <td>35 gp</td>
          <td>1d8</td>
          <td>1d10</td>
          <td>19&#8211;20/x2</td>
          <td>&#8212;</td>
          <td>6 lb.</td>
          <td>Slashing</td>
        </tr>
        <tr>
          <td className="indent">Waraxe, dwarven</td>
          <td>30 gp</td>
          <td>1d8</td>
          <td>1d10</td>
          <td>x3</td>
          <td>&#8212;</td>
          <td>8 lb.</td>
          <td>Slashing</td>
        </tr>
        <tr className="odd-row">
          <td className="indent">Whip<sup>4</sup></td>
          <td>1 gp</td>
          <td>1d2<sup>3</sup></td>
          <td>1d3<sup>3</sup></td>
          <td>x2</td>
          <td>
          </td>
          <td>2 lb.</td>
          <td>Slashing</td>
        </tr>
        <tr>
          <td><i>Two-Handed Melee Weapons</i></td>
          <td>
          </td>
          <td>
          </td>
          <td>
          </td>
          <td>
          </td>
          <td>
          </td>
          <td>
          </td>
          <td>
          </td>
        </tr>
        <tr className="odd-row">
          <td className="indent">Axe, orc double<sup>5</sup></td>
          <td>60 gp</td>
          <td>1d6/1d6</td>
          <td>1d8/1d8</td>
          <td>x3</td>
          <td>&#8212;</td>
          <td>15 lb.</td>
          <td>Slashing</td>
        </tr>
        <tr>
          <td className="indent">Chain, spiked<sup>4</sup></td>
          <td>25 gp</td>
          <td>1d6</td>
          <td>2d4</td>
          <td>x2</td>
          <td>&#8212;</td>
          <td>10 lb.</td>
          <td>Piercing</td>
        </tr>
        <tr className="odd-row">
          <td className="indent">Flail, dire<sup>5</sup></td>
          <td>90 gp</td>
          <td>1d6/1d6</td>
          <td>1d8/1d8</td>
          <td>x2</td>
          <td>&#8212;</td>
          <td>10 lb.</td>
          <td>Bludgeoning</td>
        </tr>
        <tr>
          <td className="indent">Hammer, gnome hooked<sup>5</sup></td>
          <td>20 gp</td>
          <td>1d6/1d4</td>
          <td>1d8/1d6</td>
          <td>x3/x4</td>
          <td>&#8212;</td>
          <td>6 lb.</td>
          <td>Bludgeoning and piercing</td>
        </tr>
        <tr className="odd-row">
          <td className="indent">Sword, two-bladed<sup>5</sup></td>
          <td>100 gp</td>
          <td>1d6/1d6</td>
          <td>1d8/1d8</td>
          <td>19&#8211;20/x2</td>
          <td>&#8212;</td>
          <td>10 lb.</td>
          <td>Slashing</td>
        </tr>
        <tr>
          <td className="indent">Urgrosh, dwarven<sup>5</sup></td>
          <td>50 gp</td>
          <td>1d6/1d4</td>
          <td>1d8/1d6</td>
          <td>x3</td>
          <td>&#8212;</td>
          <td>12 lb.</td>
          <td>Slashing or piercing</td>
        </tr>
        <tr className="odd-row">
          <td><i>Ranged Weapons</i></td>
          <td>
          </td>
          <td>
          </td>
          <td>
          </td>
          <td>
          </td>
          <td>
          </td>
          <td>
          </td>
          <td>
          </td>
        </tr>
        <tr>
          <td className="indent">Bolas</td>
          <td>5 gp</td>
          <td>1d3<sup>3</sup></td>
          <td>1d4<sup>3</sup></td>
          <td>x2</td>
          <td>10 ft.</td>
          <td>2 lb.</td>
          <td>Bludgeoning</td>
        </tr>
        <tr className="odd-row">
          <td className="indent">Crossbow, hand</td>
          <td>100 gp</td>
          <td>1d3</td>
          <td>1d4</td>
          <td>19&#8211;20/x2</td>
          <td>30 ft.</td>
          <td>2 lb.</td>
          <td>Piercing</td>
        </tr>
        <tr>
          <td className="indent2">Bolts (10)</td>
          <td>1 gp</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>1 lb.</td>
          <td>&#8212;</td>
        </tr>
        <tr className="odd-row">
          <td className="indent">Crossbow, repeating heavy</td>
          <td>400 gp</td>
          <td>1d8</td>
          <td>1d10</td>
          <td>19&#8211;20/x2</td>
          <td>120 ft.</td>
          <td>12 lb.</td>
          <td>Piercing</td>
        </tr>
        <tr>
          <td className="indent2">Bolts (5)</td>
          <td>1 gp</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>1 lb.</td>
          <td>&#8212;</td>
          <td>
          </td>
        </tr>
        <tr className="odd-row">
          <td className="indent">Crossbow, repeating light</td>
          <td>250 gp</td>
          <td>1d6</td>
          <td>1d8</td>
          <td>19&#8211;20/x2</td>
          <td>80 ft.</td>
          <td>6 lb.</td>
          <td>Piercing</td>
        </tr>
        <tr>
          <td className="indent2">Bolts (5)</td>
          <td>1 gp</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>1 lb.</td>
          <td>&#8212;</td>
          <td>
          </td>
        </tr>
        <tr className="odd-row">
          <td className="indent">Net</td>
          <td>20 gp</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>10 ft.</td>
          <td>6 lb.</td>
          <td>&#8212;</td>
          <td>
          </td>
        </tr>
        <tr>
          <td className="last-row" style={{
            "textIndent": "1em"
          }}>Shuriken (5)</td>
          <td className="last-row">1 gp</td>
          <td className="last-row">1</td>
          <td className="last-row">1d2</td>
          <td className="last-row">x2</td>
          <td className="last-row">10 ft.</td>
          <td className="last-row">1/2 lb.</td>
          <td className="last-row">Piercing</td>
        </tr>
        <tr>
          <td colSpan="8">1 <i>Weight figures are for Medium
weapons. A Small weapon weighs half as much, and a Large weapon weighs
twice as much.</i></td>
        </tr>
        <tr>
          <td colSpan="8">2 <i>When two types are given, the weapon
is both types if the entry specifies &#8220;and,&#8221; or either type (player&#8217;s
choice at time of attack) if the entry specifies &#8220;or.&#8221;</i></td>
        </tr>
        <tr>
          <td colSpan="8">3 <i>The weapon deals nonlethal damage
rather than lethal damage.</i></td>
        </tr>
        <tr>
          <td colSpan="8">4 <i>Reach weapon.</i></td>
        </tr>
        <tr>
          <td colSpan="8">5 <i>Double weapon.</i></td>
        </tr>
      </tbody>
    </table>
    <p>Weapons found on Table: Weapons that have special options for
the wielder (&#8220;you&#8221;) are described below. Splash weapons are described
under Special Substances and Items.</p>
    <a id="arrows"></a><p><b>Arrows</b>: An arrow used as a melee weapon is treated as a
light improvised weapon (&#8211;4 penalty on attack rolls) and deals damage
as a dagger of its size (critical multiplier x2). Arrows come in a
leather quiver that holds 20 arrows. An arrow that hits its target is
destroyed; one that misses has a 50% chance of being destroyed or lost.</p>
    <a id="orc-double-axe"></a><p><b>Axe, Orc Double</b>: An orc double axe is a double weapon.
You can fight with it as if fighting with two weapons, but if you do,
you incur all the normal attack penalties associated with fighting with
two weapons, just as if you were using a one-handed weapon and a light
weapon. A creature wielding an orc double axe in one hand can&#8217;t use it
as a double weapon&#8212;only one end of the weapon can be used in any given
round.</p>
    <a id="bolas"></a><p><b>Bolas</b>: You can use this weapon to make a ranged trip
attack against an opponent. You can&#8217;t be tripped during your own trip
attempt when using a set of bolas.</p>
    <a id="bolts"></a><p><b>Bolts</b>: A crossbow bolt used as a melee weapon is
treated as a light improvised weapon (&#8211;4 penalty on attack rolls) and
deals damage as a dagger of its size (crit x2). Bolts come in a wooden
case that holds 10 bolts (or 5, for a repeating crossbow). A bolt that
hits its target is destroyed; one that misses has a 50% chance of being
destroyed or lost.</p>
    <a id="sling-bullets"></a><p><b>Bullets, Sling</b>: Bullets come in a leather pouch that
holds 10 bullets. A bullet that hits its target is destroyed; one that
misses has a 50% chance of being destroyed or lost.</p>
    <a id="spiked-chain"></a><p><b>Chain, Spiked</b>: A spiked chain has reach, so you can
strike opponents 10 feet away with it. In addition, unlike most other
weapons with reach, it can be used against an adjacent foe.</p>
    <p>You can make trip attacks with the chain. If you are tripped
during your own trip attempt, you can drop the chain to avoid being
tripped.</p>
    <p>When using a spiked chain, you get a +2 bonus on opposed
attack rolls made to disarm an opponent (including the roll to avoid
being disarmed if such an attempt fails).</p>
    <p>You can use the Weapon Finesse feat to apply your Dexterity
modifier instead of your Strength modifier to attack rolls with a
spiked chain sized for you, even though it isn&#8217;t a light weapon for you.</p>
    <a id="hand-crossbow"></a><p><b>Crossbow, Hand</b>: You can draw a hand crossbow back by
hand. Loading a hand crossbow is a move action that provokes attacks of
opportunity.</p>
    <p>You can shoot, but not load, a hand crossbow with one hand at
no penalty. You can shoot a hand crossbow with each hand, but you take
a penalty on attack rolls as if attacking with two light weapons.</p>
    <a id="heavy-crossbow"></a><p><b>Crossbow, Heavy</b>: You draw a heavy crossbow back by
turning a small winch. Loading a heavy crossbow is a full-round action
that provokes attacks of opportunity.</p>
    <p>Normally, operating a heavy crossbow requires two hands.
However, you can shoot, but not load, a heavy crossbow with one hand at
a &#8211;4 penalty on attack rolls. You can shoot a heavy crossbow with each
hand, but you take a penalty on attack rolls as if attacking with two
one-handed weapons. This penalty is cumulative with the penalty for
one-handed firing.</p>
    <a id="light-crossbow"></a><p><b>Crossbow, Light</b>: You draw a light crossbow back by
pulling a lever. Loading a light crossbow is a move action that
provokes attacks of opportunity.</p>
    <p>Normally, operating a light crossbow requires two hands.
However, you can shoot, but not load, a light crossbow with one hand at
a &#8211;2 penalty on attack rolls. You can shoot a light crossbow with each
hand, but you take a penalty on attack rolls as if attacking with two
light weapons. This penalty is cumulative with the penalty for
one-handed firing.</p>
    <a id="repeating-crossbow"></a><p><b>Crossbow, Repeating</b>: The repeating crossbow (whether
heavy or light) holds 5 crossbow bolts. As long as it holds bolts, you
can reload it by pulling the reloading lever (a free action). Loading a
new case of 5 bolts is a full-round action that provokes attacks of
opportunity.</p>
    <p>You can fire a repeating crossbow with one hand or fire a
repeating crossbow in each hand in the same manner as you would a
normal crossbow of the same size. However, you must fire the weapon
with two hands in order to use the reloading lever, and you must use
two hands to load a new case of bolts.</p>
    <a id="dagger"></a><p><b>Dagger</b>: You get a +2 bonus on Sleight of Hand checks
made to conceal a dagger on your body (see the Sleight of Hand skill).</p>
    <a id="dire-flail"></a><p><b>Flail, Dire</b>: A dire flail is a double weapon. You can
fight with it as if fighting with two weapons, but if you do, you incur
all the normal attack penalties associated with fighting with two
weapons, just as if you were using a one-handed weapon and a light
weapon. A creature wielding a dire flail in one hand can&#8217;t use it as a
double weapon&#8212; only one end of the weapon can be used in any given
round.</p>
    <p>When using a dire flail, you get a +2 bonus on opposed attack
rolls made to disarm an enemy (including the opposed attack roll to
avoid being disarmed if such an attempt fails).</p>
    <p>You can also use this weapon to make trip attacks. If you are
tripped during your own trip attempt, you can drop the dire flail to
avoid being tripped.</p>
    <a id="flail"></a><a id="heavy-flail"></a><p><b>Flail or Heavy Flail</b>: With a flail, you get a +2 bonus
on opposed attack rolls made to disarm an enemy (including the roll to
avoid being disarmed if such an attempt fails).</p>
    <p>You can also use this weapon to make trip attacks. If you are
tripped during your own trip attempt, you can drop the flail to avoid
being tripped.</p>
    <a id="gauntlet"></a><p><b>Gauntlet</b>: This metal glove lets you deal lethal damage
rather than nonlethal damage with unarmed strikes. A strike with a
gauntlet is otherwise considered an unarmed attack. The cost and weight
given are for a single gauntlet. Medium and heavy armors (except
breastplate) come with gauntlets.</p>
    <a id="spiked-gauntlet"></a><p><b>Gauntlet, Spiked</b>: Your opponent cannot use a disarm
action to disarm you of spiked gauntlets. The cost and weight given are
for a single gauntlet. An attack with a spiked gauntlet is considered
an armed attack.</p>
    <a id="glaive"></a><p><b>Glaive</b>: A glaive has reach. You can strike opponents 10
feet away with it, but you can&#8217;t use it against an adjacent foe.</p>
    <a id="guisarme"></a><p><b>Guisarme</b>: A guisarme has reach. You can strike
opponents 10 feet away with it, but you can&#8217;t use it against an
adjacent foe.</p>
    <p>You can also use it to make trip attacks. If you are tripped
during your own trip attempt, you can drop the guisarme to avoid being
tripped.</p>
    <a id="halberd"></a><p><b>Halberd</b>: If you use a ready action to set a halberd
against a charge, you deal double damage on a successful hit against a
charging character.</p>
    <p>You can use a halberd to make trip attacks. If you are tripped
during your own trip attempt, you can drop the halberd to avoid being
tripped.</p>
    <a id="gnome-hooked-hammer"></a><p><span style={{
        "fontWeight": "bold"
      }}>Hammer, Gnome Hooked:</span>
A gnome hooked hammer is a double weapon. You can fight with it as if
fighting with two weapons, but if you do, you incur all the normal
attack penalties associated with fighting with two weapons, just as if
you were using a one-handed weapon and a light weapon. The hammer&#8217;s
blunt head is a bludgeoning weapon that deals 1d6 points of damage
(crit x3). Its hook is a piercing weapon that deals 1d4 points of
damage (crit x4). You can use either head as the primary weapon. The
other head is the offhand weapon. A creature wielding a gnome hooked
hammer in one hand can&#8217;t use it as a double weapon&#8212;only one end of the
weapon can be used in any given round.</p>
    <p>You can use a gnome hooked hammer to make trip attacks. If you
are tripped during your own trip attempt, you can drop the gnome hooked
hammer to avoid being tripped.</p>
    <p>Gnomes treat gnome hooked hammers as martial weapons.</p>
    <a id="javelin"></a><p><b>Javelin</b>: Since it is not designed for melee, you are
treated as nonproficient with it and take a &#8211;4 penalty on attack rolls
if you use a javelin as a melee weapon.</p>
    <a id="kama"></a><p><b>Kama</b>: The kama is a special monk weapon. This
designation gives a monk wielding a kama special options.</p>
    <p>You can use a kama to make trip attacks. If you are tripped
during your own trip attempt, you can drop the kama to avoid being
tripped.</p>
    <a id="lance"></a><p><b>Lance</b>: A lance deals double damage when used from the
back of a charging mount. It has reach, so you can strike opponents 10
feet away with it, but you can&#8217;t use it against an adjacent foe.</p>
    <p>While mounted, you can wield a lance with one hand.</p>
    <a id="longbow"></a><p><b>Longbow</b>: You need at least two hands to use a bow,
regardless of its size. A longbow is too unwieldy to use while you are
mounted. If you have a penalty for low Strength, apply it to damage
rolls when you use a longbow. If you have a bonus for high Strength,
you can apply it to damage rolls when you use a composite longbow (see
below) but not a regular longbow.</p>
    <a id="composite-longbow"></a><p><b>Longbow, Composite</b>: You need at least two hands to use
a bow, regardless of its size. You can use a composite longbow while
mounted. All composite bows are made with a particular strength rating
(that is, each requires a minimum Strength modifier to use with
proficiency). If your Strength bonus is less than the strength rating
of the composite bow, you can&#8217;t effectively use it, so you take a &#8211;2
penalty on attacks with it. The default composite longbow requires a
Strength modifier of +0 or higher to use with proficiency. A composite
longbow can be made with a high strength rating to take advantage of an
above-average Strength score; this feature allows you to add your
Strength bonus to damage, up to the maximum bonus indicated for the
bow. Each point of Strength bonus granted by the bow adds 100 gp to its
cost.</p>
    <p>For purposes of weapon proficiency and similar feats, a
composite longbow is treated as if it were a longbow. </p>
    <a id="longspear"></a><p><b>Longspear</b>: A longspear has reach. You can strike
opponents 10 feet away with it, but you can&#8217;t use it against an
adjacent foe. If you use a ready action to set a longspear against a
charge, you deal double damage on a successful hit against a charging
character.</p>
    <p><b><a id="net"></a>Net</b>: A net is used to entangle
enemies. When you throw
a net, you make a ranged touch attack against your target. A net&#8217;s
maximum range is 10 feet. If you hit, the target is entangled. An
entangled creature takes a &#8211;2 penalty on attack rolls and a &#8211;4 penalty
on Dexterity, can move at only half speed, and cannot charge or run. If
you control the trailing rope by succeeding on an opposed Strength
check while holding it, the entangled creature can move only within the
limits that the rope allows. If the entangled creature attempts to cast
a spell, it must make a DC 15 Concentration check or be unable to cast
the spell.</p>
    <p>An entangled creature can escape with a DC 20 Escape Artist
check (a full-round action). The net has 5 hit points and can be burst
with a DC 25 Strength check (also a full-round action).</p>
    <p>A net is useful only against creatures within one size
category of you.</p>
    <p>A net must be folded to be thrown effectively. The first time
you throw your net in a fight, you make a normal ranged touch attack
roll. After the net is unfolded, you take a &#8211;4 penalty on attack rolls
with it. It takes 2 rounds for a proficient user to fold a net and
twice that long for a nonproficient one to do so.</p>
    <a id="nunchaku"></a><p><b>Nunchaku</b>: The nunchaku is a special monk weapon. This
designation gives a monk wielding a nunchaku special options. With a
nunchaku, you get a +2 bonus on opposed attack rolls made to disarm an
enemy (including the roll to avoid being disarmed if such an attempt
fails).</p>
    <a id="quarterstaff"></a><p><b>Quarterstaff</b>: A quarterstaff is a double weapon. You
can fight with it as if fighting with two weapons, but if you do, you
incur all the normal attack penalties associated with fighting with two
weapons, just as if you were using a one-handed weapon and a light
weapon. A creature wielding a quarterstaff in one hand can&#8217;t use it as
a double weapon&#8212;only one end of the weapon can be used in any given
round. </p>
    <p>The quarterstaff is a special monk weapon. This designation
gives a monk wielding a quarterstaff special options.</p>
    <a id="ranseur"></a><p><b>Ranseur</b>: A ranseur has reach. You can strike opponents
10 feet away with it, but you can&#8217;t use it against an adjacent foe.</p>
    <p>With a ranseur, you get a +2 bonus on opposed attack rolls
made to disarm an opponent (including the roll to avoid being disarmed
if such an attempt fails).</p>
    <a id="rapier"></a><p><b>Rapier</b>: You can use the Weapon Finesse feat to apply
your Dexterity modifier instead of your Strength modifier to attack
rolls with a rapier sized for you, even though it isn&#8217;t a light weapon
for you. You can&#8217;t wield a rapier in two hands in order to apply 1-1/2
times your Strength bonus to damage.</p>
    <a id="sai"></a><p><b>Sai</b>: With a sai, you get a +4 bonus on opposed attack
rolls made to disarm an enemy (including the roll to avoid being
disarmed if such an attempt fails).</p>
    <p>The sai is a special monk weapon. This designation gives a
monk wielding a sai special options.</p>
    <a id="scythe"></a><p><b>Scythe</b>: A scythe can be used to make trip attacks. If
you are tripped during your own trip attempt, you can drop the scythe
to avoid being tripped.</p>
    <a id="shield"></a><p><b>Shield, Heavy or Light</b>: You can bash with a shield
instead of using it for defense. See Armor for details.</p>
    <a id="shortbow"></a><p><b>Shortbow</b>: You need at least two hands to use a bow,
regardless of its size. You can use a shortbow while mounted. If you
have a penalty for low Strength, apply it to damage rolls when you use
a shortbow. If you have a bonus for high Strength, you can apply it to
damage rolls when you use a composite shortbow (see below) but not a
regular shortbow. </p>
    <a id="composite-shortbow"></a><p><b>Shortbow, Composite</b>: You need at least two hands to use
a bow, regardless of its size. You can use a composite shortbow while
mounted. All composite bows are made with a particular strength rating
(that is, each requires a minimum Strength modifier to use with
proficiency). If your Strength bonus is lower than the strength rating
of the composite bow, you can&#8217;t effectively use it, so you take a &#8211;2
penalty on attacks with it. The default composite shortbow requires a
Strength modifier of +0 or higher to use with proficiency. A composite
shortbow can be made with a high strength rating to take advantage of
an above-average Strength score; this feature allows you to add your
Strength bonus to damage, up to the maximum bonus indicated for the
bow. Each point of Strength bonus granted by the bow adds 75 gp to its
cost. </p>
    <p>For purposes of weapon proficiency and similar feats, a
composite shortbow is treated as if it were a shortbow. </p>
    <a id="shortspear"></a><p><b>Shortspear</b>: A shortspear is small enough to wield
one-handed. It may also be thrown. </p>
    <a id="shuriken"></a><p><b>Shuriken</b>: A shuriken is a special monk weapon. This
designation gives a monk wielding shuriken special options. A shuriken
can&#8217;t be used as a melee weapon. </p>
    <p>Although they are thrown weapons, shuriken are treated as
ammunition for the purposes of drawing them, crafting masterwork or
otherwise special versions of them and what happens to them after they
are thrown. </p>
    <a id="siangham"></a><p><b>Siangham</b>: The siangham is a special monk weapon. This
designation gives a monk wielding a siangham special options. </p>
    <a id="sickle"></a><p><b>Sickle</b>: A sickle can be used to make trip attacks. If
you are tripped during your own trip attempt, you can drop the sickle
to avoid being tripped. </p>
    <a id="sling"></a><p><b>Sling</b>: Your Strength modifier applies to damage rolls
when you use a sling, just as it does for thrown weapons. You can fire,
but not load, a sling with one hand. Loading a sling is a move action
that requires two hands and provokes attacks of opportunity. </p>
    <p>You can hurl ordinary stones with a sling, but stones are not
as dense or as round as bullets. Thus, such an attack deals damage as
if the weapon were designed for a creature one size category smaller
than you and you take a &#8211;1 penalty on attack rolls. </p>
    <a id="spear"></a><p><b>Spear</b>: A spear can be thrown. If you use a ready action
to set a spear against a charge, you deal double damage on a successful
hit against a charging character. </p>
    <a id="spiked-armor"></a><p><b>Spiked Armor</b>: You can outfit your armor with spikes,
which can deal damage in a grapple or as a separate attack. See Armor
for details. </p>
    <a id="spiked-shield"></a><p><b>Spiked Shield, Heavy or Light</b>: You can bash with a
spiked shield instead of using it for defense. See Armor for details. </p>
    <a id="unarmed-strike"></a><p><b>Strike, Unarmed</b>: A Medium character deals 1d3 points of
nonlethal damage with an unarmed strike. A Small character deals 1d2
points of nonlethal damage. A monk or any character with the Improved
Unarmed Strike feat can deal lethal or nonlethal damage with unarmed
strikes, at her option. The damage from an unarmed strike is considered
weapon damage for the purposes of effects that give you a bonus on
weapon damage rolls. </p>
    <p>An unarmed strike is always considered a light weapon.
Therefore, you can use the Weapon Finesse feat to apply your Dexterity
modifier instead of your Strength modifier to attack rolls with an
unarmed strike. </p>
    <a id="bastard-sword"></a><p><b>Sword, Bastard</b>: A bastard sword is too large to use in
one hand without special training; thus, it is an exotic weapon. A
character can use a bastard sword two-handed as a martial weapon. </p>
    <a id="two-bladed-sword"></a><p><b>Sword, Two-Bladed</b>: A two-bladed sword is a double
weapon. You can fight with it as if fighting with two weapons, but if
you do, you incur all the normal attack penalties associated with
fighting with two weapons, just as if you were using a one-handed
weapon and a light weapon. A creature wielding a two-bladed sword in
one hand can&#8217;t use it as a double weapon&#8212;only one end of the weapon can
be used in any given round. </p>
    <a id="trident"></a><p><b>Trident</b>: This weapon can be thrown. If you use a ready
action to set a trident against a charge, you deal double damage on a
successful hit against a charging character. </p>
    <a id="dwarven-urgrosh"></a><p><b>Urgrosh, Dwarven</b>: A dwarven urgrosh is a double weapon.
You can fight with it as if fighting with two weapons, but if you do,
you incur all the normal attack penalties associated with fighting with
two weapons, just as if you were using a one-handed weapon and a light
weapon. The urgrosh&#8217;s axe head is a slashing weapon that deals 1d8
points of damage. Its spear head is a piercing weapon that deals 1d6
points of damage. You can use either head as the primary weapon. The
other is the off-hand weapon. A creature wielding a dwarven urgrosh in
one hand can&#8217;t use it as a double weapon&#8212;only one end of the weapon can
be used in any given round. </p>
    <p>If you use a ready action to set an urgrosh against a charge,
you deal double damage if you score a hit against a charging character.
If you use an urgrosh against a charging character, the spear head is
the part of the weapon that deals damage. </p>
    <p>Dwarves treat dwarven urgroshes as martial weapons. </p>
    <a id="dwarven-waraxe"></a><p><b>Waraxe, Dwarven</b>: A dwarven waraxe is too large to use
in one hand without special training; thus, it is an exotic weapon. A
Medium character can use a dwarven waraxe two-handed as a martial
weapon, or a Large creature can use it one-handed in the same way. A
dwarf treats a dwarven waraxe as a martial weapon even when using it in
one hand. </p>
    <a id="whip"></a><p><b>Whip</b>: A whip deals nonlethal damage. It deals no damage
to any creature with an armor bonus of +1 or higher or a natural armor
bonus of +3 or higher. The whip is treated as a melee weapon with
15-foot reach, though you don&#8217;t threaten the area into which you can
make an attack. In addition, unlike most other weapons with reach, you
can use it against foes anywhere within your reach (including adjacent
foes). </p>
    <p>Using a whip provokes an attack of opportunity, just as if you
had used a ranged weapon. </p>
    <p>You can make trip attacks with a whip. If you are tripped
during your own trip attempt, you can drop the whip to avoid being
tripped. </p>
    <p>When using a whip, you get a +2 bonus on opposed attack rolls
made to disarm an opponent (including the roll to keep from being
disarmed if the attack fails). </p>
    <p>You can use the Weapon Finesse feat to apply your Dexterity
modifier instead of your Strength modifier to attack rolls with a whip
sized for you, even though it isn&#8217;t a light weapon for you. </p>
    <a id="masterwork-weapons"></a><h5>MASTERWORK WEAPONS</h5>
    <p className="initial">A masterwork weapon is a finely crafted
version of a normal weapon. Wielding it provides a +1 enhancement bonus
on attack rolls.</p>
    <p>You can&#8217;t add the masterwork quality to a weapon after it is
created; it must be crafted as a masterwork weapon (see the Craft
skill). The masterwork quality adds 300 gp to the cost of a normal
weapon (or 6 gp to the cost of a single unit of ammunition). Adding the
masterwork quality to a double weapon costs twice the normal increase
(+600 gp).</p>
    <p>Masterwork ammunition is damaged (effectively destroyed) when
used. The enhancement bonus of masterwork ammunition does not stack
with any enhancement bonus of the projectile weapon firing it.</p>
    <p>All magic weapons are automatically considered to be of
masterwork quality. The enhancement bonus granted by the masterwork
quality doesn&#8217;t stack with the enhancement bonus provided by the
weapon&#8217;s magic.</p>
    <p>Even though some types of armor and shields can be used as
weapons, you can&#8217;t create a masterwork version of such an item that
confers an enhancement bonus on attack rolls. Instead, masterwork armor
and shields have lessened armor check penalties.</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      